import React from "react";


export default function Login() {
  return (
    <div className="flex  justify-center" >
      
      
      <div className="flex justify-center items-center" >
      
        <form className="w-full md:w-full lg:w-full bg-white rounded shadow-lg py-8 px-8 my-20">
        <div className="object-right-top">
        <a href="/" className="text-center text-sm font-bold mb-4"> <i class="fa fa-home" aria-hidden="true"></i> Ir a Home </a>
        </div>
        
          <h2 className="mb-2 font-bold text-2xl">Iniciar Sesión</h2>
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" for="username">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-yellow-700"
              id="username"
              type="text"
              placeholder="Usuario"
            />
          </div>
          <div className="mb-6">
            <label className="block text-grey-darker text-sm font-bold mb-2" for="password">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-yellow-700"
              id="password"
              type="password"
              placeholder="********"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-rose-500 hover:bg-rose-600 active:bg-rose-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Login
            </button>
            <a className="inline-block align-baseline font-bold text-xs text-gray-800 hover:text-rose-500" href="#">
              Olvidaste tu password?
            </a>
          </div>
          <div className="text-xs text-center mt-4">
            <p className="text-yellow-700">¿Aún no tienes cuenta? <a className="no-underline font-bold text-gray-800 hover:text-rose-500" href="/registro">Regístrate</a></p>
          </div>
        </form>
      </div>
    </div>
  );
}