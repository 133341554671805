import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Layout from "../../hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import MovilNav from "components/navigation/MovilNav";
import News from "components/boxes/news";

//images
import imgOferta from "../../assets/images/oferta-educativa.png";
import imgDeportivos from "../../assets/images/deportivos.png";
import imgCulturales from "../../assets/images/culturales.png";
import imgAcademicas from "../../assets/images/academicas.png";
import imgBiblioteca from "../../assets/images/biblioteca.png";
import imgNoDrogas from "../../assets/images/ayuda_drogas.png";
import aviso from "../../assets/images/aviso.png";

function Home(){
    const avisos = {
        ofertaEducativa: {
            title: "Revisa nuestra oferta educativa",
            description: "Estudia una carrera técnica en el CBTIS 132",
            imagePath: imgOferta,
            link: "/nuevo-ingreso"
        },
        clubesDeportivos: {
            title: "Conoce nuestros clubes deportivos",
            description: "Participa en nuestros equipos deportivos",
            imagePath: imgDeportivos,
            link: "/deportivos"
        },
        clubesCulturales: {
            title: "Conoce nuestros clubes Culturales",
            description: "Participa en nuestros equipos deportivos",
            imagePath: imgCulturales,
            link: "/culturales"
        },
        avisoFernando: {
            title: "ACTIVIDADES DEPORTIVAS Y CULTURALES",
            description: "Banda de guerra de 10am a 2pm, escolta de 8am a 10am, Basquetball femenil y varonil de 8-9:30am, volleyball femenil y varonil 8-11am, futbol femenil y varonil 8-9:30, grupo de música acústica 9:30-11am",
            imagePath: aviso,
            link: "https://api.whatsapp.com/send/?phone=526623470007&text=Hola+deseo+mas+informaci%C3%B3n+sobre+clubes+culturales&type=phone_number&app_absent=0"
        },
        asesoriasAcademicas: {
            title: "Asesorías académicas",
            description: "Cursos avanzados para alumnos sobresalientes del CBTIS 132",
            imagePath: imgAcademicas,
            link: "/alidet/#asesorias-academicas"
        },
        biblioteca: {
            title: "Biblioteca",
            description: "Consulta el catálogo de libros de la biblioteca del CBTIS 132",
            imagePath: imgBiblioteca,
            link: "/proximamente"
        },
        ayudaDrogas: {
            title: "Ayuda contra las drogas",
            description: "Si necesitas ayuda contra las drogas, nosotros te ayudamos",
            imagePath: imgNoDrogas,
            link: "/proximamente"
        },
    }
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            <Principal />
            <div className="mx-auto sm:w-90 lg:w-2/3 text-xl items-center my-5 z-0">
            {Object.keys(avisos).map((key, index) => (
                <News key={index} title={avisos[key].title} description={avisos[key].description} imagePath={avisos[key].imagePath} link={avisos[key].link} />
            ))}
            </div>
            
            <Footer />
        </Layout>
    )
}
export default Home;