function Error404(){
    return (
        <div>
            
            <div class="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
            <div className="mx-10 leading-8">

            <p><b>Error404</b></p>
            <p>La dirección solicitada o archivo no existe 🙈</p>
            <br/>
            <p> <a href="/"><i class="fa fa-home" aria-hidden="true"></i> <b>Ir a Home</b></a>   </p>
            </div>
            </div>
            
        </div>
    )
}
export default Error404;