import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Layout from "../../hocs/layouts/Layout";
import MovilNav from "components/navigation/MovilNav";
import News from "components/boxes/news";

//images
import imgDeportivos from "../../assets/images/deportivos.png";
import imgCulturales from "../../assets/images/culturales.png";

function Alumnos(){
    const alumnos = {
        clubesDeportivos: {
            title: "Conoce nuestros clubes deportivos",
            description: "Participa en nuestros equipos deportivos",
            imagePath: imgDeportivos,
            link: "/deportivos"
        },
        clubesCulturales: {
            title: "Conoce nuestros clubes Culturales",
            description: "Participa en nuestros equipos deportivos",
            imagePath: imgCulturales,
            link: "/culturales"
        },
    }
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            <h1>Alumnos</h1>
            <h2 id="horario">Horario semestral</h2>
            <h2 id="servicio">Liberación de servicio social</h2>
            <h2 id="titulacion">Titulación</h2>
            <h2 id="orientacion">Orientación educativa</h2>
            <h2 id="asesorias">Asesorías</h2>
            <div className="mx-auto sm:w-90 lg:w-2/3 text-xl items-center my-5 z-0">
            {Object.keys(alumnos).map((key, index) => (
                <News key={index} title={alumnos[key].title} description={alumnos[key].description} imagePath={alumnos[key].imagePath} link={alumnos[key].link} />
            ))}
            </div>
            <Footer />
        </Layout>
    )
}
export default Alumnos;