import Principal from "../../components/headers/Principal";
import MovilNav from "components/navigation/MovilNav";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Layout from "../../hocs/layouts/Layout";
import Oferta from "components/boxes/oferta";


//Images:
import alimentos from '../../assets/images/alimentos.png';
import programacion from '../../assets/images/programacion.png';
import construccion from '../../assets/images/construction.png';
import dietetica from '../../assets/images/dietetica.png';
import administracion from '../../assets/images/admin-rh.png';
import laboratorista from '../../assets/images/laboratorista.png';
import enfermeria from '../../assets/images/enfermeria.png';

let x = 0;

const CarrerasEspecialidades = [
  {title : "Carrera técnica en Producción Industrial de Alimentos",
  description : "La Carrera de Producción Industrial de Alimentos Otorgada por el CBTIS 132 ofrece un enfoque integrado en la producción de alimentos, desde la selección de las materias primas, el procesamiento de los alimentos, la calidad y seguridad de los alimentos, procesos de fabricación, embalaje y distribución. Los estudiantes adquieren conocimientos en tecnología de alimentos, higiene y salud, ingeniería industrial, manufactura, control de la calidad y seguridad de los alimentos y una perspectiva global para analizar procesos y productos de la industria de alimentos. El plan de estudios se enfoca en la preparación de técnicos profesionales con destrezas científicas, tecnológicas y administrativas para diseñar, desarrollar, fabricar y vigilar la calidad de los alimentos.",
  link: "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/333508001-13.pdf",
  imagePath : alimentos
  },
  {
  title : "Carrera técnica en Programación",
  description : "La Carrera Técnica en Programación del CBTIS 132 ofrece un programa de estudios que tiene como objetivo preparar a los estudiantes para entrar al entorno laboral en áreas de programación informática. Esta carrera enfoca en enseñar los conceptos necesarios para adquirir un amplio conocimiento de programación en una variedad de disciplinas de la informática. Los estudiantes aprenderán cómo desarrollar aplicaciones de programación informática desde el desarrollo y el análisis de sistemas hasta la programación e integración de bases de datos. Asimismo, los programas de estudio incluyen una amplia gama de herramientas modernas de programación. Los estudiantes adquirirán habilidades de lenguajes de programación, como Java, php, HTML, CSS y JavaScript, para desarrollar aplicaciones eficientes y mantener una óptima arquitectura en sistemas informáticos. El programa también se enfoca en el análisis y diseño de soluciones, desarrollo de repositorios de base de datos, puesta en marcha de interfaz de usuario, plataformas basadas en Web y tecnologías de Big Data. Además, los estudiantes tendrán la opción de asignaturas opcionales donde podrán ampliar aún más sus conocimientos técnicos en el área de programación. Por esto y más, la carrera técnica en programación del CBTIS 132 puede ser una excelente opción para los estudiantes que desean obtener un conocimiento profundo de los lenguajes de programación modernos.",
  link : "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/344100002-13.pdf",
  imagePath : programacion
},
  {
  title : "Carrera técnica en Construcción",
  description : "¡La carrera Técnica en Construcción del CBTIS 132 es la opción ideal para aquellos que buscan una carrera emocionante y desafiante! Esta carrera ofrece la oportunidad de adquirir habilidades en construcción y técnicas de proyectos nuevos y antiguos. Los alumnos aprenderán cómo planear y diseñar edificaciones, estructuras y sistemas. Algunos de los temas cubiertos en el currículo incluyen el análisis estructural, la arquitectura y la gestión de proyectos. Además, los alumnos recibirán instrucciones en áreas tales como seguridad de obra, construcción de maquinaria y presupuestos. Al concluir la formación, los alumnos no sólo estarán preparados para ingresar al sector de la construcción, sino que también podrán disfrutar de una gran cantidad de ofertas de trabajo en Sonora y otras partes del mundo. Aquellos que se gradúen con los mejores resultados tendrán aún más oportunidades de obtener mejores puestos de trabajo.",
  imagePath : construccion,
  link : "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/353200001-13.pdf"
  },
  {
  title : "Carrera técnica en Dietética",
  description : "La Carrera Técnica en Dietética del CBTIS 132 prepara a nuestros estudiantes para obtener un título técnico, donde se enseñan materias esenciales a la hora de crear y desarrollar planes de nutrición saludable como la elaboración de planes de alimentación saludable para la prevención y tratamiento de enfermedades. Enfocado para aquellos estudiantes interesados en estudiar el impacto de la nutrición en la salud y bienestar de las personas, ¡esta carrera ofrece una inmensa oportunidad de empleo! Cualquier persona interesada en desempeñar un trabajo como Nutricionista en Sonora debe saber que existen muchas oportunidades de trabajo en la región que se adaptan a los requisitos de la carrera de Dietética del CBTIS 132. La escasez de Nutricionistas cualificados deja una gran oferta de trabajo para aquellos estudiantes de la carrera de Dietética del CBTIS 132.",
  imagePath : dietetica,
  link : "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/371500001-13.pdf"
  },
  {
  title : "Carrera técnica en Administración de Recursos Humanos",
  description : "La carrera técnica en Administración de Recursos Humanos del Centro de Bachillerato Tecnológico Industrial y de Servicios (CBTIS) número 132 ofrece a sus estudiantes los conocimientos y habilidades para gestionar con eficiencia un negocio de cualquier tamaño. Obtendrás una gran cantidad de herramientas para mejorar los procesos de reclutamiento, selección, capacitación y desarrollo de tus colaboradores. Con esta carrera aprenderás técnicas de liderazgo, dirección y evaluación de rendimiento, lo cual lo preparará para que seas un gran emprendedor en el campo de los Recursos Humanos y tengas éxito como empresario.",
  imagePath : administracion,
  link : "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/333502001-13.pdf"
  },
  {
  title : "Carrera técnica en Laboratorista Químico",
  description : "La carrera técnica en Laboratorista Químico del CBTIS 132 es ideal para aquellas personas interesadas en estudiar ciencias químicas y de la salud. Esta carrera ofrece un gran repertorio en conocimientos básicos, tanto teóricos como prácticos, sobre las diferentes herramientas usadas en los laboratorios químicos, además de la toma de medidas necesarias y su análisis de los resultados obtenidos, por lo cual, proporciona a quien la estudia el panorama para orientar su carrera en dicha área. Esto, a su vez, abre la puerta al estudio de posgrados y maestrías relacionadas a la química y la salud, los cuales serán de gran utilidad en la vida profesional del egresado.",
  imagePath : laboratorista,
  link : "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/341100001-13.pdf"
  },
  {
  title : "Carrera técnica en Enfermería general",
  description : "La carrera técnica en Enfermería General del CBTIS 132 es una oportunidad única para adquirir los conocimientos y habilidades necesarios para mejorar el cuidado de salud de la población. Esta carrera brinda a los alumnos experiencias prácticas en las áreas de salud en Sonora, al mismo tiempo que examina conceptos básicos de enfermería, habilidades de comunicación eficaces de enfermería y algunos de los procedimientos de cuidado estandarizados. Es un beneficio adicional que, al término de sus estudios, los alumnos de CBTIS 132 tengan muchas oportunidades laborales en el sector de la salud en Sonora, especialmente con la gran oferta en el ámbito de enfermería. Además, los enfermeros también contribuirán a mitigar cualquier crisis sanitaria inminente. Esto es especialmente importante dada la pronta llegada de enfermedades infecciosas, lo que hace que los enfermeros sean un recurso esencial para la salud pública.",
  imagePath : enfermeria,
  link : "http://www.dgeti.sep.gob.mx/images/multimediaDgeti/OfertaEducativaPlanteles/CarrerasEspecialidades/planesEstudio/371200001-13.pdf"
  }
]

function cambiaCarrera () {
  if (x < 6) {
   x += 1;
  } else {
   x = 0;
  }
};

function NuevoIngreso(){  
   
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            <h2>Bienvenidos Estudiantes de Nuevo Ingreso</h2>
            <div class="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
            <div className="mx-10 leading-8">
            <p>¡Bienvenido a la familia <span className="text-rose-500">LOBOS!</span></p>
            <p>En esta sección encontrarás información relevante para tu ingreso al CBTIS 132, orgullosamente <span className="text-rose-500">LOBOS</span></p>
            <p>Para iniciar tu proceso de inscripción, deberás realizar los siguientes pasos:</p>
            </div>
            <br />
            <ol className="border rounded-md bg-emerald-900 p-8 text-yellow-50 leading-8 my-10">
                <li className="hover:text-rose-300 active:text-rose-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Realizar tu pre-registro en línea en la página de la <span ><a class="link" href="https://www.gob.mx/tramites/ficha/inscripcion-al-nivel-medio-superior-en-linea/SEMS1001">SEMS</a></span></li>
                <li className="hover:text-rose-300 active:text-rose-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Sigue la convocatoria de <span ><a class="link" href="https://prepason.sonora.edu.mx">Prepa Sonora</a></span> para realizar tu examen de admisión, asegúrate de poner al CBTIS 132 como tu primer opción</li>
                
                <li className="hover:text-rose-300 active:text-rose-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Una vez que hayas realizado tu pre-registro, deberás presentarte en el plantel con la siguiente documentación:</li>
                <ul className="indent-4">
                    <li className="hover:text-gray-300 active:text-gray-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Acta de nacimiento (original y copia)</li>
                    <li className="hover:text-gray-300 active:text-gray-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> CURP (original y copia)</li>
                    <li className="hover:text-gray-300 active:text-gray-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Certificado de secundaria (original y copia)</li>
                    <li className="hover:text-gray-300 active:text-gray-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Comprobante de domicilio (original y copia)</li>
                </ul>
                <li className="hover:text-rose-300 active:text-rose-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Una vez que hayas entregado tu documentación, deberás realizar tu pago de inscripción en el banco.</li>
                <li className="hover:text-rose-300 active:text-rose-200"><i class="fa fa-check-square-o" aria-hidden="true"></i> Una vez que hayas realizado tu pago, deberás presentarte en el plantel con tu comprobante de pago para que te entreguen tu horario.</li>
            </ol>
            <div className="mx-10">
            <p>Si tienes alguna duda, puedes comunicarte con nosotros a través de nuestras redes sociales o al teléfono:</p>
            <p>
            <a className="text-rose-500 hover:text-gray-500" href='tel:6623101190'><i class="fa fa-phone" aria-hidden="true"></i> 6623101190</a>
            </p>
            </div>
            
            </div>
            
            <div className="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
            <h2 id="oferta-educativa">Revisa nuestra Oferta Educativa</h2>
    
            </div>
            
            
            {
                <>
                
                
                <Oferta />
                </>

            }

            

            <Footer />
        </Layout>
    )
}
export default NuevoIngreso;