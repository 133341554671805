import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import MovilNav from "components/navigation/MovilNav";
import Layout from "../../hocs/layouts/Layout";
import React from "react";

function Docentes(){
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            <h1>Docentes</h1>
            <hr />
            <h2>Horarios</h2>
                        
            <h2>Formatos Servicios Docentes</h2>
            <h2>Construye-T</h2>
            <h2>Tutorías SINATA</h2>
            <h2>Fomento a la lectura PRONAFOLE</h2>
            <Footer />
        </Layout>
    )
}
export default Docentes;