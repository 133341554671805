import RegistrationForm from "components/forms/Registro2";
import Layout from "../../hocs/layouts/Layout";

function Logeo(){
    return (
        <Layout>
            
            <RegistrationForm />
            
        </Layout>
    )
}
export default Logeo;