import Layout from "../../hocs/layouts/Layout";
import Club from "../../components/boxes/club";
//imagenes
import Construccion from "../../assets/images/construction.png";

function Proximamente(){
    return (
        <Layout>

        <div>
            
            <div class="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
            <div className="mx-10 leading-8">
            <center>
            <Club 
                title="Próximamente"
                description="Estamos trabajando en esta sección, pronto estará disponible."
                imagePath={Construccion}
            />
            <br/>
            <p> <a href="/"><i class="fa fa-home" aria-hidden="true"></i> <b>Ir a Home</b></a>   </p>
            </center>
            </div>
            </div>
            
        </div>
        
        </Layout>
    )
}
export default Proximamente;