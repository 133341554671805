import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import MovilNav from "components/navigation/MovilNav";
import Layout from "../../hocs/layouts/Layout";
import Article from "components/boxes/article";
//images
import voleibolImg from "../../assets/images/deportes1.png";
import futbolImg from "../../assets/images/deportes2.png";
import basketballImg from "../../assets/images/deportes3.png";
import ajedrezImg from "../../assets/images/deportes4.png";
import taekwondoImg from "../../assets/images/deportes5.png";
import boxeoImg from "../../assets/images/deportes6.png";

function Deportivos(){
    const deportivos = {
        voleibol: {
        title: "Club de Voleibol",
        description: "El Club de Voleibol del CBTIS 132 ofrece un entorno divertido, saludable e inclusivo para los estudiantes interesados en el voleibol. En el club, los estudiantes pueden mejorar su habilidad y técnica al practicar los principios básicos del juego y perfeccionar sus conocimientos. Los entrenadores experimentados y profesionales brindan asesoramiento individual para preparar a los estudiantes para que lleguen al nivel necesario para la competición de voleibol. La escuela desarrollará habilidades importantes fuera de la cancha, como trabajo en equipo, motivación y liderazgo. El enfoque del programa es ayudar a los estudiantes a convertirse en mejores jugadores y en individuos responsables y comprometidos con el deporte. Los equipos representarán al CBTIS 132 en competencias locales e internacionales.",
        imagePath: voleibolImg,
        },
        soccer: {
        title: "Club de Fútbol",
        description: "El Club de Fútbol del CBTIS 132 es un club deportivo con el objetivo de difundir los valores deportivos de la educación integral. Contamos con equipos para niños, adolescentes y adultos. Estamos comprometidos a promover el respeto, la integridad y el trabajo duro en nuestro equipo. Estamos orgullosos de nuestros atletas y nos esforzamos para llevarlos al análisis, minimizar el riesgo de lesiones, mejorar la técnica y entrenar la resistencia. Amamos lo que hacemos y nos enfocamos a entregar un entrenamiento de clase mundial y un excelente ambiente para los jugadores. Ofrecemos la oportunidad de jugar con otros equipos locales y, en el proceso, de desarrollar relaciones duraderas y solidarias, que alentamos y promovemos la salud emocional, mental y física de todos los involucrados. Seguimos adelante con la presencia de nuestro Presidente y Presidenta en los partidos y el apoyo de la directiva de CBTIS132.",
        imagePath: futbolImg,
        },
        basketball: {
        title: "Club de Baloncesto",
        description: "El Club de Basketball CBTIS 132 de la Preparatoria cuenta con un excelente equipo formado por estudiantes motivados e inspirados a lograr todo lo que aspiran. Nuestro equipo de entrenadores y jugadores afrontan el desafío juntos. Entrenamos a los jugadores para que mejoren su juego mediante lecciones técnicas diseñadas para ayudarlos a alcanzar el máximo nivel de su destreza. Además, la asistencia a juegos y competencias, es una parte importante de nuestro programa de aprendizaje. En el famoso club de Basketball CBTIS, el trabajo en equipo y la actitud constructiva de los jugadores es fuente de orgullo en la preparatoria.",
        imagePath: basketballImg,
        },
        ajedrez: {
        title: "Club de Ajedrez",
        description: "El Club de Ajedrez de la Preparatoria CBTIS 132 es un grupo destinado a desarrollar en los estudiantes habilidades tácticas, estrategias de juego, y una apreciación por la disciplina, la dedicación y la paciencia. Nuestro objetivo es formar atletas mejor preparados para enfrentarse a los desafíos del ajedrez, y despertar en nuestros miembros una pasión para la disciplina. Nuestras actividades incluyen entrenamientos específicos de ajedrez bajo la dirección de instructores experimentados, clases interactivas sobre comprensión de estrategias de juego y tácticas aplicadas, análisis de partidas, torneos intermensuales, así como desafíos de juego entre miembros. Invitamos a todos los estudiantes que buscan mejorar su habilidad en ajedrez para unirse a nuestro club.",
        imagePath: ajedrezImg,
        },
        taekwondo: {
        title: "Club de Taekwondo",
        description: "El club de Tae Kwon Do de la Preparatoria CBTIS 132 se enorgullece de nuestra comunidad. Ofrecemos entrenamiento en artes marciales de alto nivel para todos los estudiantes, desde principiantes hasta expertos. Nuestros instructores altamente calificados enseñan movimientos formales y habilidades de ataque, defensa, autodefensa y meditación con técnicas coreanas tradicionales. Nuestros alumnos tendrán la oportunidad de formar una legión de luchadores respetuosos, leales y confiables. El club siempre se mantiene a la vanguardia de lo que hay de mejor en las artes marciales en todo el mundo. Estamos comprometidos con la salud, los hábitos alimenticios y los entrenamientos, además de un ambiente seguro y profesional. Nuestra meta es educar a un grupo de ciudadanos educados con el método tradicional Tae Kwon Do. ¡Te esperamos!",
        imagePath: taekwondoImg,
        },
        boxeo: {
        title: "Club de Boxeo",
        description: "El Club de Boxeo de CBTIS 132 ofrece a los estudiantes la oportunidad de aprender un deporte que es ideal para disciplina física, mental y emocional. Los entrenadores de nuestro club son expertos, con amplia experiencia en el boxeo, donde se enfatiza en el desarrollo de habilidades y estructura para poder mejorar cada día. Ofrecemos entrenamientos por la mañana, tarde y noche para acomodar a todos nuestros alumnos. Estamos comprometidos con la construcción de la confianza de nuestros alumnos y su autoconfianza, para que cada uno pueda competir en el nivel en el que se encuentren. Estamos comprometidos con el desarrollo de los estudiantes para convertirse en líderes fuertes, positivas y saludables tanto en el aula como el ring. ¡Únete al Club de Boxeo de CBTIS 132 para descubrir una nueva manera de vivir!",
        imagePath: boxeoImg,
        },
    }
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            <h1>Clubes deportivos</h1>
            {Object.keys(deportivos).map((key, index) => (
                <Article
                    key={index}
                    title={deportivos[key].title}
                    description={deportivos[key].description}
                    imagePath={deportivos[key].imagePath}
                />
            
            ))}
            <div className="mx-auto sm:w-90 lg:w-2/3 text-yellow-50 text-xl items-center my-5 p-8 text-justify my-10">
            <p className="bg-emerald-950 rounded-md p-[10px] text-gray-100 mx-10 my-5">Si deseas más información sobre los clubes deportivos, puedes contactar al coordinador de deporte, el maestro <strong>Sergio Isaí Hernández</strong>, a su número <a class="link" href="tel:6231177770">6231177770</a> 
            </p>
            <p className="bg-emerald-950 rounded-md p-[10px] text-gray-100 mx-10 my-5 text-center">Puedes mandar <i class="fa fa-whatsapp" aria-hidden="true"></i> whatsApp <a className="link" href="https://wa.me/526231177770?text=Hola%20deseo%20mas%20información%20sobre%20clubes%20deportivos">  aquí</a> </p>
            </div>
            
            <Footer />
        </Layout>
    )
}
export default Deportivos;