import {connect} from 'react-redux';
import '../../styles/index.css';
import {Link} from 'react-router-dom';
import logo_dgeti from 'assets/images/CBTIS132_logo.png';
import React, { useState } from 'react';

function Navbar(){
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return(
    <nav className='w-full py-4 top-0 fixed bg-emerald-950 text-yellow-50 z-5'>
    <div className="-ml-4 -mt-2 flex flex-wrap items-center  justify-around sm:flex-nowrap md:px-14 md:px-1 z-5">
    <div className="-ml-4 -mt-2 flex flex-wrap items-center  justify-around sm:flex-nowrap md:px-14 z-5">
        <Link to="/" className='ml-4 mt-2'>
        <img
          src={logo_dgeti}
          width={140}
          height={150}
          className=''/>
        </Link>
        
      <div className="ml-4 mt-2 flex-shrink-0">
      
        <button onClick={toggle} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-amber-50 hover:text-yellow-50 hover:bg-rose-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-rose-500 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false"/>
      </div>
      <div className="md:block md:ml-10 md:pr-4 md:space-x-8 justify-around">
        <div className='flex max-sm:hidden' id="mobile-menu">
        <Link to="/nuevo-ingreso" className='text-lg inline-flex font-small leading-6 text-gray-200  border-b-2 border-emerald-950 active:bg-emerald-950 active:text-rose-500  hover:border-rose-500 hover:text-yellow-50 mx-4'><p><i class="fa fa-user-plus" aria-hidden="true"></i> Nuevo Ingreso</p> </Link>
      
        <Link to="/alumnos" className='text-lg inline-flex font-small leading-6 text-gray-200 active:bg-emerald-950 active:text-rose-500  border-b-2 border-emerald-950 hover:border-rose-500 hover:text-yellow-50 mx-4'><p><i class="fa fa-users" aria-hidden="true"></i> Alumnos</p></Link>
    
        <Link to="/docentes" className='text-lg inline-flex font-small leading-6 text-gray-200 active:bg-emerald-950 active:text-rose-500  border-b-2 border-emerald-950 hover:border-rose-500 hover:text-yellow-50 mx-4'><p><i class="fa fa-graduation-cap" aria-hidden="true"></i>  Docentes</p></Link>
     
        <Link to="/alidet" className='text-lg inline-flex font-small leading-6 text-gray-200  border-b-2 active:bg-emerald-950 active:text-rose-500 border-emerald-950 hover:border-rose-500 hover:text-yellow-50 mx-4'><p><i class="fa fa-flask" aria-hidden="true"></i>  ALIDET</p></Link>
        </div>
        </div>
        <div>
        <Link to="/login" className='button-primary-small'>Login</Link>
        <Link to="/registro" className='button-secondary-small'>Registro</Link>
        </div>
        
        
    </div> 
    </div>
    </nav>
    );
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {
     
})(Navbar);