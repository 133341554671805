import Sway from "components/boxes/sway";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Layout from "../../hocs/layouts/Layout";
import { useState } from "react";

function ComiteEscolar(){
    const avisos = [
        {
            title: "Avisos a la comunidad",
            description: "En este espacio, el comité escolar de padres de familia publicará avisos a la comunidad, como evidencias de gastos públicos.",
            link: "https://sway.office.com/s/A6eRHE95zvO1k5UB/embed"
        },
        {
            title: "Avisos a la comunidad",
            description: "En este espacio, el comité escolar de padres de familia publicará avisos a la comunidad, como evidencias de gastos públicos.",
            link: "https://sway.office.com/s/bFadjglcjIFDsvwA/embed"
        }
    ]
    const [x, setX] = useState(0);

    const increase = () => {
        if(x === avisos.length - 1){
        setX(0);
        }
        else{
        setX(x + 1);
        }
    };
    const decrease = () => {
        if(x === 0){
        setX(avisos.length - 1);
        }
        else{
        setX(x - 1);
        }
  };
    return (
        <Layout>
            <Navbar />
            <h1>Comité Escolar</h1>
            <>
            <center>
                <div className="bg-emerald-900 flex justify-center items-center md:flex-row">
                <button
                            type="button"
                            className="button-primary-small mx-8"
                            style={{width: '60px'}}
                            onClick={decrease}
                            >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </button>
                <p className="font-semibold text-yellow-50 mx-8">{x + 1} / {avisos.length}</p>
                <button
                            type="button"
                            style={{width: '60px'}}
                            className="button-primary-small mx-8"
                            onClick={increase}
                            >
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
                </div>
                <Sway
                    title={avisos[x].title}
                    description={avisos[x].description}
                    link={avisos[x].link}
                />
            </center>
            </>
            
            <Footer />
        </Layout>
    )
}
export default ComiteEscolar;