import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from './store';
import { Provider } from 'react-redux';
import Error404 from "containers/errors/Error404";
import Home from "containers/pages/Home";
import Alidet from "containers/pages/Alidet";
import NuevoIngreso from "containers/pages/NuevoIngreso";
import Alumnos from "containers/pages/Alumnos";
import Docentes from "containers/pages/Docentes";
import Deportivos from "containers/pages/Deportivos";
import Culturales from "containers/pages/Culturales";
import Legal from "containers/pages/Legal";
import Administrativos from "containers/pages/Administrativos";
import Registro from "containers/pages/Registro";
import Logeo from "containers/pages/Logeo";
import ComiteEscolar from "containers/pages/ComiteEscolar";
import Proximamente from "containers/pages/Proximamente";


function App() {
  return (
    <Provider store={store}>
    
    <Router>
      <Routes>
        {/* Error Display */}
        <Route path="*" element={<Error404 />} />
        {/* Home */}
        <Route path="/administrativos" element={<Administrativos />} />
        <Route path="/alidet" element={<Alidet />} />
        <Route path="/nuevo-ingreso" element={<NuevoIngreso />} />
        <Route path="/alumnos" element={<Alumnos />} />
        <Route path="/docentes" element={<Docentes />} />
        <Route path="/deportivos" element={<Deportivos />} />
        <Route path="/culturales" element={<Culturales />} />
        <Route path="/aviso-legal" element={<Legal />} />
        <Route path="/login" element={<Logeo />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/comite-escolar" element={<ComiteEscolar />} />
        <Route path="/proximamente" element={<Proximamente />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
    </Provider>
  );
}

export default App;
