import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../../styles/index.css';

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      name: "",
      specialty: "",
      turno: "matutino",
      email: "",
      password: "",
      confirmPassword: ""
    };
  }

  handleUsernameChange = event => {
    this.setState({
      username: event.target.value
    });
  };

  handleNameChange = event => {
    this.setState({
      name: event.target.value
    });
  };

  handleSpecialtyChange = event => {
    this.setState({
      specialty: event.target.value
    });
  };

  handleTurnoChange = event => {
    this.setState({
      turno: event.target.value
    });
  };

  handleEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  };

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value
    });
  };

  handleConfirmPasswordChange = event => {
    this.setState({
      confirmPassword: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { username, name, specialty, turno, email, password, confirmPassword } = this.state;
    // enviar data al servidor
  };

  render() {
    return (
      <div className=" flex justify-center my-20">
        
        <form
        onSubmit={this.handleSubmit}
        className="bg-gray-300 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full md:w-2/3 max-w-2xl flex flex-col"
        >
          
          <h1 className="text-center text-2xl font-bold mb-4">Registro</h1>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-emerald-800 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Nombre de usuario"
              value={this.state.username}
              onChange={this.handleUsernameChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="name">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-emerald-800 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Nombre completo"
              value={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-emerald-800 text-sm font-bold mb-2" for="email">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-emerald-800 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder="Email.cb132@dgeti.sems.gob.mx"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="specialty">
              
            </label>
            <span className="text-gray-700">Especialidad:</span>
    <select name="name" onChange={this.handleSpecialtyChange} className="text-emerald-800 w-full border rounded p-2">
      <option>Construcción</option>
      <option>Programación</option>
      <option>Laboratorista químico</option>
      <option>Dietética</option>
      <option>Prod. Industrial de Alimentos</option>
      <option>Admin. en R. H.</option>
      <option>Enfermería general</option>
    </select>
          </div>
          <div className="mb-4 flex">
            <div className="flex flex-col items-start">
              <label className="block text-gray-700 text-sm font-bold mb-2 pt-2" for="turno">
              
                
              </label>
              
              <div className="flex items-center">
                <span className="mr-2 text-gray-700">Turno</span>
                <span className="inline-block w-2/3">
                  <p className="small">Matutino</p>
                  <input
                    className="mr-2 leading-tight"
                    type="radio"
                    name="turno"
                    value="matutino"
                    checked={this.state.turno === "matutino"}
                    onChange={this.handleTurnoChange}
                  />
                </span>
                <span className="inline-block w-1/3">
                  <p className="small " >Vespertino</p>
                  <input
                    className="mr-2 leading-tight"
                    type="radio"
                    name="turno"
                    value="vespertino"
                    checked={this.state.turno === "vespertino"}
                    onChange={this.handleTurnoChange}
                  />
                </span>
              </div>
            </div>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="password">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-emerald-800 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="Contraseña"
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" for="confirmPassword">
              
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-emerald-800 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="Repita la contraseña"
              value={this.state.confirmPassword}
              onChange={this.handleConfirmPasswordChange}
            />
          </div>
          <div className="flex items-center px-4">
            <input
              className="button-primary-small"
              type="submit"
              value="Registrar"
            />
            
          </div>
          
          <hr class="h-px my-8 bg-gray-400 border-0 dark:bg-gray-700"/>
          <div className="flex items-center px-4">
            <p className="text-gray-700 text-sm">
              ¿Ya tienes una cuenta? Inicia sesión {" "}
            
            <Link to="/login" className="text-rose-500 hover:text-rose-800">
                aquí
            </Link>
            </p>
            
            
          </div>
          <br/>
          <div className="flex items-center px-4">
          <p>
            <a href="/" className="text-center text-sm font-bold mb-4"> <i class="fa fa-home" aria-hidden="true"></i> Ir a Home </a>
          </p>
          </div>
            
        </form>
      </div>
    );
  }
}

export default RegistrationForm;