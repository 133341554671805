import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Layout from "../../hocs/layouts/Layout";

function Administrativos(){
    return (
        <Layout>
            <Navbar />
            <h1>Administrativos</h1>    
            <Footer />
        </Layout>
    )
}
export default Administrativos;