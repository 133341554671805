import {connect} from 'react-redux';
import React, { useState } from 'react';
import '../../styles/index.css';
import { Component } from "react";
import PropTypes from "prop-types";

class Sway extends Component {
  render() {
    const { link, title, description } = this.props;

    return (
      <center>
        <div
        className="w-full md:w-1/2 lg:w-1/3 rounded-lg mb-4 bg-emerald-900 "
        
      >
        <h3
          className="text-rose-500 text-center text-2xl bg-gray-100 font-semibold hover:opacity-100"
        >
          {title}
        </h3>
        <iframe width="760px" height="500px" src={link} frameborder="0" marginheight="0" marginwidth="0" max-width="100%" sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts" scrolling="no" class="border-none max-w-full max-h-screen" allowfullscreen mozallowfullscreen msallowfullscreen webkitallowfullscreen></iframe>
        <p
          className="text-center text-gray-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        >
          {description}
        </p>
      </div>
      </center>
      
    );
  }
}

Sway.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default(Sway);