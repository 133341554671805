import React from 'react';
import Modal from 'react-modal';

class ImageContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    const { imagePath, imageAlt} = this.props;
    return (
      <div className="flex justify-center flex-col lg:h-1/2 md:w-2/3 lg:w-2/3 xl:w-full">
        <img
          src={imagePath}
          alt={imageAlt}
          className="w-full h-full object-cover object-center border-4 border-blue-400 rounded-lg shadow-lg"
          onClick={ this.openModal }
        />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
        >
        <img
            src={imagePath}
            alt={imageAlt}
            className="w-full h-full lg:h-1/2 md:w-2/3 lg:w-1/2 xl:w-1/3"
            onClick={ this.closeModal }
          />
        </Modal>
      </div>
    );
  }
}

export default(ImageContainer);