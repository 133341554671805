import {connect} from 'react-redux';
import logo_dgeti from 'assets/images/CBTIS132_logo.png';
import CBTIS132 from 'assets/images/CBTIS132.png';
import logo_educacion from 'assets/images/logo-educacion-sep.png';
import logo_dgeti2 from 'assets/images/Imagotipo_DGETI_horizontal.png';
var localTime = new Date();
var year = localTime.getFullYear();

const navigation = {
    nuevo_ingreso: [
      { name: 'Oferta Educativa', href: '/nuevo-ingreso/#oferta-educativa' },
      { name: 'Programas extracurriculares', href: '#' },
      { name: 'Trayectoria', href: '#' },
      { name: 'CAED Centro de Atención para estudiantes con Discapacidad', href: '#' },
    ],
    alumnos: [
      { name: 'Horario semestral', href: '/alumnos/#horario' },
      { name: 'Liberación servicio social', href: '/alumnos/#servicio-social' },
      { name: 'Titulación', href: '/alumnos/#titulacion' },
      { name: 'Orientación educativa', href: '/alumnos/#orientacion' },
      { name: 'Asesorías', href: '/alumnos/#asesorias' },
      { name: 'Clubes deportivos', href: '/deportivos' },
      { name: 'Clubes culturales', href: '/culturales' },
    ],
    docentes: [
      { name: 'Tutorías SINATA', href: '/docentes/#tutorias' },
      { name: 'Construye-T', href: '/docentes/#construye-t' },
      { name: 'Fomento a Lectura PRONAFOLE', href: 'docentes/#pronafole' },
      { name: 'Horarios', href: '/docentes/#horario' },
      { name: 'Formatos', href: '/docentes/#formatos' },
    ],
    alidet: [
      { name: 'Eventos', href: '/alidet/#eventos' },
      { name: 'Asesorías académicas', href: '/alidet/#academicas' },
      { name: 'Club de Ciencias', href: 'alidet/#ciencias' },
      { name: 'Prototipos tecnológicos', href: '/alidet/#prototipos' },
      { name: 'Cápsulas de ciencia', href: '/alidet/#blog' },
    ],
    legal: [
      { name: 'Transparencia Comité escolar', href: '/comite-escolar' },
      { name: 'Privacidad', href: '/aviso-legal/#privacidad' },
      { name: 'Términos y condiciones', href: '/aviso-legal/#terminos-uso' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/profile.php?id=100063857149017',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      
    ],
  }

function Footer(){
    return(
        <footer id="footer" className="bg-emerald-950" aria-labelledby="footer-heading">
        
        <div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-rows-3 grid-flow-col gap-4">
              

              <img
                className='row-span-3'
                src={CBTIS132}
                alt="Company name"
                width={100}
                height={100}
              />
              <div className='col-span-2'>
              <p className='text-amber-50 ' > 
                <b>Centro de Bachillerato Tecnológico, Industrial y de Servicios N° 132</b> 
                </p>
                <p className='text-amber-50'>Av Quinta Mayor 183, Las Quintas, 83240 Hermosillo, Sonora, México.
                </p>

              </div>
              
              
              <div className="row-span-2 col-span-2 ...">
                <p>
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-rose-500 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" /><b>CBTis No.132 - Oficial</b> </a>
                ))}
                </p>
                <br/>
                <p>
                <a className="text-rose-500 hover:text-gray-500" href='tel:6623101190'><i class="fa fa-phone" aria-hidden="true"></i> 6623101190</a>
                </p>
                
              
              
              </div>
            </div>
            <h3 className='bg-emerald-950 text-yellow-50'>Mapa del sitio</h3>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-medium text-rose-500 ">Nuevo ingreso</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.nuevo_ingreso.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-400 hover:text-yellow-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-rose-500">Alumnos</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.alumnos.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-400 hover:text-yellow-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-medium text-rose-500">Docentes</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.docentes.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-400 hover:text-yellow-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className="text-base font-medium text-rose-500">ALIDET</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.alidet.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-400 hover:text-yellow-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-1 md:gap-8">
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-rose-500">Aviso legal</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-400 hover:text-yellow-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-yellow-50 xl:text-center"> {year} Dirección General de Educación Tecnológica Industrial (DGETI) </p>
            <div class="horizontal-container">
            <img className='mx-auto' src={logo_educacion} alt="Company name" width={400} height={200} />
            <img className='mx-auto' src={logo_dgeti2} alt="Company name" width={320} height={200} />
            </div>
          </div>
        </div>
      </footer>
    );
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {
     
})(Footer);