import '../../styles/index.css';
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';

class News extends Component {
  render() {
    const { imagePath, title, description, link } = this.props;
    

    return (
      <article
      className="mx-auto sm:w-90 lg:w-2/ text-xl items-center my-5 border rounded-md p-8 text-justify contenedor z-0"
      >
        <img width="100%" src={imagePath} alt=""/>
        <div class="text-wrapper"><h2>{title}</h2></div>
        <div class="centrado">
        <h3>{description}</h3>
        <p class="card">Haz click <Link to={link} className='link'>
        aquí
        </Link> para más información</p>
        </div>
        
      </article>
    );
  }
}

News.propTypes = {
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default (News);