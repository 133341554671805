import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import MovilNav from "components/navigation/MovilNav";
import Layout from "../../hocs/layouts/Layout";
import Article from "components/boxes/article";
// Images:
import musica from "../../assets/images/club_musica.png";
import teatro from "../../assets/images/club_teatro.png";
import fotografia from "../../assets/images/club_fotografia.png";
import podcast from "../../assets/images/club_podcast.png";
import artesVisuales from "../../assets/images/club_visuales.png";
import porristas from "../../assets/images/club_porristas.png";
import bandaGuerra from "../../assets/images/banda_de_guerra_cbtis132.png";
import escoltaBandera from "../../assets/images/escolta_bandera_lobos_cbtis132.png";

function Culturales(){
    const clubesCulturales = {
        musica: {
            title: "Club de música",
            description: "En este club de música formarás parte de un grupo de auténtico regional mexicano. Aprende a tocar un instrumento si todavía no sabes tocar guitarra o tololoche, y entrena tu voz para cantar al estilo de los grandes de la época dorada mexicana.",
            imagePath: musica,
        },
        bandaGuerra: {
            title: "Club Banda de Guerra",
            description: "El club de banda de guerra lo conforman estudiantes que, con la intención de aprender a ejecutar un instrumento musical como la corneta y el tambor, se reúnen a aprender los diferentes toques de ordenanza y marchas militares, y a su ves ejecutar toques con mayor dificultad con rutinas de libre creatividad. Lo anterior mencionado con el fin de representar a Cbtis 132 en las diferentes competencias tanto locales como estatales y nacionales.",
            imagePath: bandaGuerra,
        },
        escoltaBandera: {
            title: "Club escolta de Bandera",
            description: "El club de escolta de bandera lo conforman estudiantes que con gran gallardía y sentido del patriotismo tienen la intención de practicar y aprender todas las instrucciones de Orden Cerrado que ejecuta una Escolta de Bandera Nacional, con la gran responsabilidad de portar nuestro lábaro patrio estos jóvenes en coordinación con el club de banda de guerra representan a Cbtis 132 tanto eventos como en competencias a nivel local, estatal y nacional.",
            imagePath: escoltaBandera
        },
        teatro: {
            title: "Club de teatro",
            description: "El club de teatro de la preparatoria es un grupo divertido y entusiasta de estudiantes que se reúnen para explorar el teatro. A través de ensayos, actuaciones y proyectos creativos, los miembros del club aprenden habilidades importantes y desarrollan su pasión por el teatro. Los miembros también tienen la oportunidad de conocer a otros estudiantes con intereses similares y formar una comunidad divertida y apasionada.",
            imagePath: teatro,
        },
        fotografia: {
            title: "Club de fotografía",
            description: "El Club de Fotografía del CBTIS 132 es una comunidad de alumnos dedicada a la exploración y práctica de la fotografía. Estamos comprometidos a conectar a los estudiantes con el mundo de la fotografía, a través de talleres, excursiones, charlas, intercambios de ideas y mucho más. Nuestro objetivo es promover el aprendizaje y la creatividad, al mismo tiempo que fomentamos el respeto por la diversidad y la tolerancia. Si te gusta la fotografía, nosotros te invitamos a unirte a nuestra comunidad.",
            imagePath: fotografia,
        },
        podcast: {
            title: "Club de podcast",
            description: "El club de podcast es un grupo de estudiantes que se reúnen para crear contenido de audio. Los miembros del club aprenden a grabar, editar y publicar sus propios podcasts. El club también ofrece talleres sobre cómo crear un podcast, cómo grabar un podcast y cómo publicar un podcast. Los miembros del club también tienen la oportunidad de conocer a otros estudiantes con intereses similares y formar una comunidad divertida y apasionada.",
            imagePath: podcast,
        },
        artesVisuales: {
            title: "Club de artes visuales",
            description: "El club de artes visuales es un grupo de estudiantes que se reúnen para explorar el arte. A través de talleres, excursiones, charlas, intercambios de ideas y mucho más, los miembros del club aprenden habilidades importantes y desarrollan su pasión por el arte. Los miembros también tienen la oportunidad de conocer a otros estudiantes con intereses similares y formar una comunidad divertida y apasionada.",
            imagePath: artesVisuales,
        },
        porristas: {
            title: "Club de porristas",
            description: "El club de porristas es un grupo de estudiantes que se reúnen para practicar y aprender a ser porristas. A través de talleres, excursiones, charlas, intercambios de ideas y mucho más, los miembros del club aprenden habilidades importantes y desarrollan su pasión por el arte. Los miembros también tienen la oportunidad de conocer a otros estudiantes con intereses similares y formar una comunidad divertida y apasionada.",
            imagePath: porristas,
        },
    }
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            <h1>Clubes culturales</h1>
            <h3>Clubes disponibles</h3>
            <div className="flex flex-wrap justify-center">
            
            {Object.keys(clubesCulturales).map((key, index) => (
                <Article
                    key={index}
                    title={clubesCulturales[key].title}
                    description={clubesCulturales[key].description}
                    imagePath={clubesCulturales[key].imagePath}
                />
            ))}

            </div>
            <div className="mx-auto sm:w-90 lg:w-2/3 text-yellow-50 text-xl items-center my-5 p-8 text-justify my-10">
            

            <p className="bg-emerald-950 rounded-md p-[10px] text-gray-100 mx-10 my-5">Si deseas más información sobre los clubes culturales, puedes contactar al coordinador de cultura, el profesor <strong>Fernando Chong</strong>, a su número <a class="link" href="tel:662347007">662 347 0007</a> 
            </p>
            <p className="bg-emerald-950 rounded-md p-[10px] text-gray-100 mx-10 my-5 text-center">Puedes mandar <i class="fa fa-whatsapp" aria-hidden="true"></i> whatsApp <a className="link" href="https://wa.me/526623470007?text=Hola%20deseo%20mas%20información%20sobre%20clubes%20culturales">  aquí</a> </p>
            </div>
            <Footer />
        </Layout>
    )
}
export default Culturales;