import Layout from "../../hocs/layouts/Layout";
import Login from "../../components/forms/Login";

function Logeo(){
    return (
        <Layout>
            
            <Login/>
            
        </Layout>
    )
}
export default Logeo;