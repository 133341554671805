import {connect} from 'react-redux';
import '../../styles/index.css';
import {Link} from 'react-router-dom';

function MovilNav(){
    return(
        <div className='md:hidden lg:hidden w-full py-4 bg-emerald-800 text-yellow-50'>
        <div className='flex '>
        <Link to="/nuevo-ingreso" className='text-lg inline-flex font-small leading-6 text-gray-200  border-b-2 border-emerald-950 active:bg-emerald-950 active:text-rose-500  hover:border-rose-500 hover:text-yellow-50 mx-4'><p className='small'><i class="fa fa-user-plus" aria-hidden="true"></i> Nuevo Ingreso</p> </Link>
      
        <Link to="/alumnos" className='text-lg inline-flex font-small leading-6 text-gray-200 active:bg-emerald-950 active:text-rose-500  border-b-2 border-emerald-950 hover:border-rose-500 hover:text-yellow-50 mx-4'><p className='small'><i class="fa fa-users" aria-hidden="true"></i> Alumnos</p></Link>
    
        <Link to="/docentes" className='text-lg inline-flex font-small leading-6 text-gray-200 active:bg-emerald-950 active:text-rose-500  border-b-2 border-emerald-950 hover:border-rose-500 hover:text-yellow-50 mx-4'><p className='small'><i class="fa fa-graduation-cap" aria-hidden="true"></i>  Docentes</p></Link>
     
        <Link to="/alidet" className='text-lg inline-flex font-small leading-6 text-gray-200  border-b-2 active:bg-emerald-950 active:text-rose-500 border-emerald-950 hover:border-rose-500 hover:text-yellow-50 mx-4'><p className='small'><i class="fa fa-flask" aria-hidden="true"></i>  ALIDET</p></Link>
        </div>
        </div>
    );
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {
     
})(MovilNav);