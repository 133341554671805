import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Layout from "../../hocs/layouts/Layout";
import MovilNav from "components/navigation/MovilNav";
import Article from "components/boxes/article";
import Sway from "components/boxes/sway";
import { useState } from 'react';
//images
import ciencia1 from "../../assets/images/ciencia1.png";
import ciencia2 from "../../assets/images/ciencia2.png";
import ciencia3 from "../../assets/images/ciencia3.png";
import ciencia4 from "../../assets/images/ciencia4.png";
import ciencia5 from "../../assets/images/ciencia5.png";
import didactic_menu from "../../assets/images/prototipos1.png";

function Alidet(){
    const ciencia = [
        {
            title: "Las Plantas Crecen Hacia la Luz: Descubriendo el Fototropismo y La Vanofilia",
            description: "Una vanofilia o fototropismo es una respuesta biológica en la que la planta crece hacia una fuente de luz. Esta respuesta fototrópica es una característica fascinante de la biología vegetal. Una de las primeras observaciones de esta respuesta fue descubierta por Charles Darwin, quien notó que incluso en el espacio abierto, los brotes de una planta florecían hacia la luz. Esta respuesta se debe a la movilización de unas células, llamados auxina, a su alrededor, lo que ayuda a dirigir el crecimiento de la planta hacia la luz. Esto se hace mediante la producción de ácido indolacético (AIA), una glándula de los tallos y las hojas que funciona como olores para las plantas. Esto se produce en respuesta a la luz, y es útil porque una planta que se mueve hacia la luz también está bien iluminada para la fotosíntesis. Esto muestra cómo la biología evolucionada ha permitido sobrevivir a las plantas, haciendo que dirijan su crecimiento hacia su fuente de alimento más importante.",
            imagePath: ciencia1,
        },
        {
            title: "Descubre cómo la Química está moldeando nuestro mundo",
            description: "La Química juega un papel fundamental en nuestras vidas, desde la fabricación de productos básicos para nuestra vida diaria hasta la industria de productos mucho más avanzados. Una de sus ramas que más se desarrolla en la actualidad es la Química de los Biopolímeros. Esta rama de la Química se enfoca en el estudio de la interacción entre los distintos materiales orgánicos y los nuevos productos biodegradables. Los biopolímeros están hechos desde polímeros naturales como la celulosa, almidón y lignina y luego se combinan con polímeros sintéticos para obtener productos más resistentes. Estos nuevos materiales tienen una amplia gama de usos, desde la fabricación de envases biodegradables, contenedores para alimentos, textiles y utensilios para el hogar. Estos materiales son duraderos, resistentes a la humedad y pueden ser reciclados o biodegradables, ofreciendo también una solución sostenible para la protección de nuestro medio ambiente.",
            imagePath: ciencia2,
        },
        {
            title: "El descubrimiento de la formación de las galaxias a través de la observación de Nebula",
            description: "La Observación de las nebulosas ha aportado una nueva profundidad para entender la formación de las galaxias. Las nebulosas son estructuras formas por la acumulación de masa estelar y gas, rodeados por la emisión de luz. Estas estructuras tienen un gran tamaño y complejidad, lo que ha permitido la detección de discos, barras y anillos de polvo y gas a través del uso de telescopio avanzados. Estas Observaciones también nos permiten entender cómo las galaxias evolucionan, además de permitirnos identificar los posibles mecanismos de formación y comprensión de galaxias. Estudiar las Nebula nos permite un mejor entendimiento de la historia de la vida de la galaxia y su composición.",
            imagePath: ciencia3,
        },
        {
            title: "El medio ambiente: preservando los ambientes naturales para un futuro sostenible",
            description: "La ecología es una ciencia que estudia los ecosistemas y los recursos naturales para mejorar la forma en que las personas y la naturaleza interactúan. El cuidado del medio ambiente es un esfuerzo continuo para garantizar la conservación de los ambientes naturales. Esto implica hacer cambios en la forma en que se producen y utilizan productos, reducir los desechos y el consumo de recursos, así como regímenes de manejo adecuado de la tierra. Esto ayuda a proteger la biodiversidad y los recursos naturales para las generaciones presentes y futuras.",
            imagePath: ciencia4,
        },
        {
            title: "La importancia de la ciencia en la vida cotidiana",
            description: "Las matemáticas aplicadas a la ingeniería de la construcción frecuentemente se usan para calcular los requerimientos estructurales, tales como la cantidad y configuración de materiales requeridos para sostener la estructura. Estas matemáticas son aplicadas a estudiar los diferentes campos de estructuras, tales como los puentes, edificios, tuberías y los elementos no estructurales, tal como la finalización. Los conceptos matemáticos se emplean para estudiar cargas y resistencia, dimensionamiento de elementos y detalles constructivos que determinan los criterios de diseño. Estos conceptos matemáticos incluyen álgebra, geometría, cálculo estructural y cálculos tridimensionales.",
            imagePath: ciencia5,
        },
    ];

    const prototipos = {
        didactic_menu: {
            title: "Didactic Menu",
            description: "Didactic Menu es un proyecto que busca mejorar la calidad de la educación en México, a través de la creación de un menú didáctico que permita a los niños aprender de una manera más divertida y dinámica. En el marco del XXV Concurso Nacional de Prototipos y Proyectos de Emprendimiento realizado en Puebla, Puebla, con la participación de 447 proyectos académicos de 31 delegaciones estatales, de los cuales 72 fueron categoría docente,  las maestras María Isabel López Vizcarra y María Guadalupe Casillas Vera, con la asesoría del maestro Félix Medina Velázquez, obtuvieron el segundo lugar en la categoría Docente Didáctico con el prototipo denominado “Didactic Menú” , ganando la certificación “Science México” para la fase internacional en Paraguay, con la intención de ganar reconocimiento para nuestro país en dicha etapa. (2023)",
            imagePath: didactic_menu,
        }
    }
    const [x, setX] = useState(0);

    const increase = () => {
        if(x === ciencia.length - 1){
        setX(0);
        }
        else{
        setX(x + 1);
        }
    };
    const decrease = () => {
        if(x === 0){
        setX(5);
        }
        else{
        setX(x - 1);
        }
  };
    return (
        <Layout>
            <Navbar />
            <MovilNav />
            
            <h1>Academia Local de Investigación y Desarrollo Tecnológico</h1>
            <div class="mx-auto sm:w-90 lg:w-2/3 text-gray-700 text-xl items-center my-5">
            <div className="mx-10 leading-8">
            <p>La Academia Local de Investigación y Desarrollo Tecnológico de la escuela preparatoria CBTIS 132 se ha convertido en un referente en la comunidad educativa de Hermosillo, Sonora, al promover la formación de jóvenes investigadores y tecnólogos comprometidos con la innovación y el avance científico. A través de su labor, ALIDET contribuye a la formación integral de los estudiantes, preparándolos para enfrentar los retos tecnológicos y científicos del siglo XXI.</p>
            </div>
            </div>
            <h2 id="eventos">Eventos</h2>
            <h2 id="academicas">Asesorías Académicas</h2>
            
            
            <Sway
                title="Cursos de alto rendimiento"
                description="Cursos avanzados de física de parte de Arjé Club de Ciencias. Inscríbete para llevar cursos los sábados, prepárate para competencias estatales y nacionales. Participa en proyectos de robótica y programación. Imagina y comparte tus ideas sobre la astrofísica, la física cuántica y el conocimiento en general."
                link="https://sway.office.com/s/FpjxeNe05gb9we2T/embed"
            />
            
            <h2 id="club-ciencias">Club de ciencias</h2>
            <Sway 
                title="Método científico"
                description="El método científico nos permite determinar la validez del conocimiento. Aprende a usarlo para resolver problemas de la vida cotidiana."
                link="https://sway.office.com/s/HUCC3P6R9BeNVOdE/embed"
               
            />
            <h2 id="prototipos">Prototipos tecnológicos</h2>
            {Object.keys(prototipos).map((key, index) => (
                <Article
                    key={index}
                    title={prototipos[key].title}
                    description={prototipos[key].description}
                    imagePath={prototipos[key].imagePath}
                />
                ))}
            <h2 id="capsulas-ciencia">Cápsulas de ciencia</h2>
            <>
            <center>
                <div className="bg-emerald-900 flex justify-center items-center md:flex-row">
                    <button
                                type="button"
                                className="button-primary-small mx-8"
                                style={{width: '60px'}}
                                onClick={decrease}
                                >
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>
                    <p className="font-semibold text-yellow-50 mx-8">{x + 1} / {ciencia.length}</p>
                    <button
                                type="button"
                                style={{width: '60px'}}
                                className="button-primary-small mx-8"
                                onClick={increase}
                                >
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                </div>
                <Article
                    title={ciencia[x].title}
                    description={ciencia[x].description}
                    imagePath={ciencia[x].imagePath}
                />
            </center>
            </>
            <Footer />
        </Layout>
    )
}
export default Alidet;