import {connect} from 'react-redux';
import '../../styles/index.css';
import React, { Component } from "react";
import PropTypes from "prop-types";
import ImageContainer from './imageModal';

class Article extends Component {
  render() {
    const { imagePath, imageAlt, title, description} = this.props;

    return (
        
        <article className="mx-auto sm:w-90 lg:w-2/3 text-yellow-50 text-xl items-center my-5 border rounded-md bg-emerald-800 p-8 text-justify">
        <h3>{title}</h3>
        <p className='py-10'>{description}</p>
        <div className='w-full max-w-xs mx-auto hover:opacity-60'>
        <ImageContainer imagePath={imagePath} imageAlt={imageAlt} />
        </div>
        
        </article>
    );
  }
}

Article.propTypes = {
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default(Article);