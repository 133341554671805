import {connect} from 'react-redux';
import '../../styles/index.css';
import CBTIS132_portada from 'assets/videos/CBTIS132-portada.mp4';
import portada from 'assets/images/portada.png';
 
const quotes =[ ['"La educación es el arma más poderosa que puedes usar para cambiar el mundo"', 'Nelson Mandela'],
[' "La sabiduría está en descubrir una gran verdad a partir de pequeños detalles"', 'Goethe'],
['"La educación debe aprovechar el pasado para preparar el futuro"', 'John Dewey'],
['"La educación es el pasaporte hacia el futuro, el mañana pertenece a aquellos que se preparan para él en el día de hoy"', 'Malcolm X'],
['"La motivación es lo que nos lleva a comenzar y lo que nos ayuda a seguir"', 'Jim Rohn'],
['"La motivación es la clave para el éxito"', 'Stephen Covey'],
['"La motivación es lo que te pone en marcha, el hábito es lo que hace que sigas"', 'Jim Ryun'],
['"La sabiduría es el resultado de una buena educación"', 'Platón'],
['"Lo que con mucho trabajo se adquiere, más se ama"', 'Aristóteles'],
['"Vive como si fueses a morir mañana. Aprende como si fueses a vivir siempre"', 'Mahatma Gandhi'],
['"Si no persigues lo que quieres, nunca lo tendrás. Si no vas hacia delante, siempre estarás en el mismo lugar"', 'Nora Roberts'],
['"No juzgues cada día por lo que cosechas, sino por las semillas que plantas"', 'Robert Louis Stevenson'],
['"El éxito es la suma de pequeños esfuerzos repetidos día tras día"', 'Robert Collier'],
['"Hay una fuerza motriz más poderosa que el vapor, la electricidad y la energía atómica: la voluntad"', 'Albert Einstein'],
['"Siempre se puede, cuando se quiere"', 'José Luis Sampedro'],
['"Solo existen dos días en el año en que no se puede hacer nada. Uno se llama ayer y otro mañana. Por lo tanto hoy es el día ideal para amar, crecer, hacer y, principalmente, vivir"', 'Dalai Lama'],
['"Nuestra mayor debilidad reside en rendirnos. La forma más segura de tener éxito es intentarlo una vez más"', 'Thomas A. Edison'],
];

function Principal(){
    var num = Math.floor(Math.random() * 17); 
    var quote = quotes[num][0];
    var author = quotes[num][1];
        return(
            <div>
            <h1 >Centro de Bachillerato Tecnológico Industrial y de Servicios N° 132</h1>
            <video autoPlay muted loop poster={portada} className='z-0'>
            <source src={CBTIS132_portada} type="video/mp4"/>Your browser does not support the video tag.
            </video>

            
            
            
            <hr/>
            <  div class="card__text"> 
            <p class="text-center"> {quote}</p>
            <p class="text-center"><b>{author}</b></p>
            </div>
            </div>
        )
        
   
}

const mapStateToProps = (state)=>({

})

export default connect(mapStateToProps, {
     
})(Principal);