import {connect} from 'react-redux';
import '../../styles/index.css';
import React, { Component } from "react";
import PropTypes from "prop-types";

class Club extends Component {
  render() {
    const { imagePath, title, description } = this.props;

    return (
      <div
        className="w-full md:w-1/2 lg:w-1/3 rounded-lg mb-4 "
        style={{
          backgroundImage: `url(${imagePath})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          padding: "10%",
          
          
        }}
      >
        <h3
          className="text-rose-500 text-center text-2xl font-semibold hover:opacity-100"
          style={{ backgroundColor: "gray-100" }}
        >
          {title}
        </h3>
        <p
          className="text-center text-gray-100"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
        >
          {description}
        </p>
      </div>
    );
  }
}

Club.propTypes = {
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default(Club);