import Principal from "../../components/headers/Principal";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import Layout from "../../hocs/layouts/Layout";

function Legal(){
    return (
        <Layout>
            <Navbar />
            <h1>Aviso Legal</h1>
            <h2 id='privacidad'>Política de privacidad</h2>
            <h2>Política de cookies</h2>
            <h2 id='terminos-uso'>Condiciones de uso</h2>
            
            <Footer />
        </Layout>
    )
}
export default Legal;